@import "../../../styles/config.scss";

.image {
    @include detailImage;
}

.flower {
    position: absolute;
    top: 4%;
    left: 33%;
    width: 35%;
    @include elementsAnimation(4s);

    &.animate {
        animation-name: moveY;
    }
}

.star {
    position: absolute;
    top: 14%;
    left: 45%;
    width: 12%;
    height: 13%;
    background-color: #fdc400;
}

.flare {
    position: absolute;
    top: 15%;
    left: 48.5%;
    width: 3.5%;
    transform: rotate(0deg);

    &.animate {
        opacity: 0;
        transition: transform 3s ease-out 1.6s, opacity 0.3s ease-in 4s;
        transform: translate(78%, 28%) rotate(90deg);
    }
}

@keyframes moveY {
    0% {
        transform: translateY(-7px);
    }
    50% {
        transform: translateY(0%);
    }
    100% {
        transform: translateY(-7px);
    }
}
