$GTSBold: "GTS Bold", sans-serif;
$GSBold: "GS Bold", sans-serif;
$GSMed: "GS Med", sans-serif;

$animationTime: 1.5s;
$pageAnimationTime: 0.35s;
$pageDelay: $animationTime - $pageAnimationTime;

$buttonAnimationTime: 0.2s;
$buttonDelay: $animationTime - $buttonAnimationTime;

$ZMap: 1;
$ZPage: $ZMap + 1;
$ZPageButton: $ZPage + 1;
$ZSubstrate: $ZPageButton + 1;
