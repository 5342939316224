@import "../../../styles/config.scss";

.answerButton {
    flex-shrink: 0;
    width: 80px;
    height: 80px;
    margin-right: 32px;
    padding: 0;
    font-family: $GSMed;
    font-size: 28px;
    border-radius: 50%;
}

.answerContainer {
    display: flex;
    align-items: center;
    max-width: 525px;
    margin: 10px;
    text-align: left;

    &.1 {
        grid-area: ans1;
    }

    &.2 {
        grid-area: ans2;
    }

    &.3 {
        grid-area: ans3;
    }

    &.4 {
        grid-area: ans4;
    }
}

@media (max-width: $screen-xl), (max-height: $screen-lg) {
    .answerButton {
        width: 70px;
        height: 70px;
        font-size: 24px;
    }

    .answerContainer {
        margin-bottom: 32px;
    }
}

@media (max-width: $screen-tablet) {
    .answerButton {
        width: 65px;
        height: 65px;
        font-size: 24px;
    }
    .answer {
        font-size: 24px;
        line-height: 30px;
    }
    .answerContainer {
        margin: 10px 10px 10px 0;
    }
}

@media (max-width: $screen-mobile) {
    margin: 10px 0;
}
