@import "../../styles/config.scss";
@import "../../styles/shared/logo.scss";

.root {
    position: fixed;
    top: -100%;
    z-index: $ZSubstrate;
    width: 100%;
    height: 100%;
    background-color: $dark;
    transition: transform 0.8s ease-in;
    transform: translateY(0%);

    &.open {
        transition: transform 0.8s ease-out;
        transform: translateY(100%);
    }
}

.closeButton {
    position: absolute;
    top: 32px;
    right: 32px;
    height: auto;
    padding: 0;
    border: 0;

    &,
    &:focus,
    &:hover,
    &:active {
        background-color: transparent !important;
    }
}

.dotsLeft,
.dotsRight,
.dotsRightCompleted,
.dotsLeftExit {
    position: absolute;
    width: 22.4%;
    height: auto;
    user-select: none;
}

.dotsLeft {
    bottom: 0;
    left: -14.5%;
    transform: rotate(270deg);
}

.dotsRight {
    top: 27%;
    right: -15.2%;
    transform: rotate(270deg);
}

// .dotsRightCompleted {
//     right: 0;
//     bottom: 0;
//     width: auto;
// }

// .dotsLeftExit {
//     bottom: 0;
//     left: 0;
//     width: auto;
// }

@media (max-width: $screen-tablet) {
    .closeButton {
        top: 24px;
        right: 28px;
    }

    .dotsLeft {
        left: 0;
        width: 150px;
        transform: rotate(0);
    }

    .dotsRight {
        display: none;
    }
}

@media (max-width: $screen-mobile-landscape) and (orientation: landscape) {
    .root {
        overflow-y: scroll;
        padding: 0 16px;

        &.forVideos {
            overflow-y: hidden;
        }
    }
    .closeButton {
        top: 16px;
        right: 68px;
    }
    .dotsLeft {
        display: none;
    }
}

@media (max-width: $screen-mobile) {
    .closeButton {
        right: 28px;
    }

    .dotsLeft {
        display: none;
    }

    .root {
        top: -100%;
        overflow-y: scroll;
        min-height: 100%;
        padding: 0 24px 80px 24px;

        &.forVideos {
            overflow-y: hidden;
        }
    }
}
