@import "../../../styles/config.scss";

.testQuestion {
    position: absolute;
    z-index: 0;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 65%;
    margin: 0 auto;
    font-family: $GSMed;
    text-align: center;
    animation-name: open;
    animation-duration: 0.6s;

    &.current {
        z-index: 1;
        display: flex;
    }
}

@keyframes open {
    0% {
        display: block;
        visibility: hidden;
        opacity: 0;
    }
    100% {
        display: block;
        visibility: visible;
        opacity: 1;
    }
}

.questionNumber {
    margin-bottom: 24px;
    font-family: $GTSBold;
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
}

.question {
    max-width: 90%;
    margin: 0 auto 78px auto;
    font-size: 60px;
}

.answersContainer {
    display: inline-grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "ans1 ans3" "ans2 ans4";
    margin: 0 auto;
    font-size: 28px;
}

@media (max-width: $screen-xl), (max-height: $screen-lg) {
    .question {
        margin-bottom: 46px;
        font-size: 42px;
    }

    .answersContainer {
        font-size: 20px;
    }
}

@media (max-height: $screen-md) {
    .questionNumber {
        margin-bottom: 22px;
        font-size: 18px;
    }

    .question {
        margin-bottom: 26px;
        font-size: 36px;
    }
}

@media (max-width: $screen-tablet) {
    .testQuestion {
        position: unset;
        align-items: start;
        max-width: 768px;
    }

    .questionNumber {
        margin-bottom: 0;
        font-size: 13px;
    }

    .question {
        max-width: 720px;
        margin: 0;
        padding: 12px 0 33px 0;
        font-size: 36px;
        text-align: left;
    }

    .answersContainer {
        width: 720px;
        margin: 0;
    }
}

@media (max-width: $screen-tablet-mini) {
    .testQuestion {
        align-items: start;
        padding: 16px 24px;
    }

    .question {
        margin: 0;
        padding: 12px 0 33px 0;
    }

    .questionNumber {
        margin-bottom: 0;
    }

    .answersContainer {
        margin: 0;
    }
}

@media (max-width: $screen-mobile-landscape) and (orientation: landscape) {
    .question {
        margin: 0 0 33px 0;
        padding: 0;
        font-size: 28px;
        line-height: 34px;
        text-align: left;
    }

    .testQuestion {
        align-items: flex-start;
        width: auto;
        height: auto;
    }

    .questionNumber {
        margin-bottom: 12px;
        font-size: 13px;
        line-height: 18px;
        text-align: left;
    }

    .answersContainer {
        margin: 0;
        padding: 0;
    }
}

@media (max-width: $screen-mobile) {
    .testQuestion {
        align-items: flex-start;
        width: 100%;
        height: auto;
        padding: 0;
    }

    .questionNumber {
        font-size: 13px;
        line-height: 18px;
        text-align: left;
    }

    .question {
        max-width: 100%;
        margin: 0 0 33px 0;
        padding: 0;
        font-size: 28px;
        line-height: 34px;
        text-align: left;
    }

    .answersContainer {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-areas: "ans1" "ans2" "ans3" "ans4";
        width: auto;
        max-width: 100%;
        margin: 0;
        padding: 0;
    }
}
