@import "../../../styles/config.scss";

.image {
    @include detailImage;
}

.figure {
    position: absolute;
    top: 21%;
    left: 52%;
    width: 25%;
    @include elementsAnimation(8s);

    &.animate {
        animation-name: rotate;
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    20% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(90deg);
    }
    45% {
        transform: rotate(90deg);
    }
    50% {
        transform: rotate(180deg);
    }
    70% {
        transform: rotate(180deg);
    }
    75% {
        transform: rotate(270deg);
    }
    95% {
        transform: rotate(270deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
