@import "../../styles/config.scss";

.button {
    @include button($textColor);
}

a.button {
    display: inline-flex;
}

// size
.normal {
    @include normalButton;
}

.large {
    @include largeButton;
}

// color
.default {
    @include colorButton($default, $defaultHover, $defaultPressed, $textColor);
}

.dark {
    @include colorButton($dark, $darkHover, $darkPressed, $textColor);
}

.yellow {
    @include colorButton(#93ea20, #93ea20, #93ea20, $dark);

    border-color: $dark;
}

.light {
    @include colorButton($light, $light, $light, $dark);

    &:hover:not(:disabled),
    &:focus:not(:disabled) {
        opacity: 0.85;
    }

    &:active:not(:disabled) {
        opacity: 0.7;
    }

    &.outlined {
        opacity: 1;

        &:hover:not(:disabled),
        &:focus:not(:disabled) {
            opacity: 1;
            color: $dark;
            background-color: $light;
        }

        &:active:not(:disabled) {
            opacity: 0.7;
        }
    }
}

.button:disabled {
    opacity: 0.5;
    cursor: auto;
}

.disabledLink {
    cursor: auto;
    pointer-events: none;
}
