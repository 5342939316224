@import "../../styles/config.scss";

.header {
    background-color: white;
}

.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 32px;
    font-family: $GTSBold;
    font-size: 16px;
    font-weight: 700;

    & > div {
        display: flex;
        align-items: center;
        & > * {
            &:first-child {
                margin-right: auto;
            }

            &:not(:first-child) {
                margin-top: 40px;
                margin-bottom: 40px;
                margin-left: 32px;
            }
        }
    }
}

.logoContainer {
    position: relative;
}

.logoButton {
    position: relative;
    @include button($dark);

    border: 0;
}

.mainLinkContainer {
    position: absolute;
    z-index: $ZPageButton;
    opacity: 0;
    padding-top: 48px;
    outline: none;
    transition: opacity 0.5s, transform 0.1s linear 0.5s;
    transform: translateY(-1000px);
    cursor: pointer;

    &.show {
        opacity: 1;
        transition: opacity 0.5s linear 0.0001s, transform 0.0001s linear;
        transform: translateY(0);
    }

    .mainLink {
        & > svg {
            width: 26px;
            height: 26px;
            margin-right: 4px;
            fill: $light;
        }
    }
}

.logo {
    width: 180px;
    height: auto;
    vertical-align: middle;
}

.link {
    color: $textDark;
    line-height: 1;
    text-decoration: none;
    text-transform: uppercase;
    transition: color 0.15s ease-in-out;

    &:hover,
    &:focus {
        color: $default;
    }

    &:active {
        color: $defaultPressed;
    }
}

@media (max-width: $screen-tablet) {
    .nav {
        margin: 0 24px;
    }

    .logo {
        width: auto;
        height: 32px;
        margin: 16px 0;
    }
}

@media (max-width: $screen-mobile-landscape) and (orientation: landscape) {
    .nav {
        margin: 0 64px;
    }
}
