@import "../../styles/config.scss";

.root {
    @include button($dark);

    font-size: 18px;
    text-transform: none;
    justify-content: flex-start;
    transition: color 0.15s ease-in-out;

    & * {
        transition: fill 0.15s ease-in-out, stroke 0.15s ease-in-out;
    }

    & > svg {
        margin: 0 12px;
    }

    & .outlined {
        & > circle {
            stroke: $dark;
            stroke-width: 3;
        }
        & > path {
            fill: $dark;
        }
    }

    & .contained {
        & > circle {
            stroke: $dark;
            stroke-width: 3;
            fill: $dark;
        }

        & > path {
            fill: $bgColor;
        }
    }

    /* stylelint-disable no-descending-specificity */
    &:hover:not(:disabled),
    &:focus:not(:disabled) {
        color: $darkHover;

        & .outlined,
        & .contained {
            & > circle {
                stroke: $darkHover;
                fill: $darkHover;
            }
            & > path {
                fill: $light;
            }
        }
    }

    &:active:not(:disabled) {
        color: $darkPressed;

        & .outlined,
        & .contained {
            & > circle {
                stroke: $darkPressed;
                fill: $darkPressed;
            }
            & > path {
                fill: $light;
            }
        }
    }

    &:disabled {
        color: #4c7d84;
        cursor: auto;

        & circle {
            stroke: #4c7d84;
            fill: #4c7d84;
        }
    }
}

@media (max-width: $screen-xl), (max-height: $screen-md) {
    .root {
        font-size: 16px;
    }
}

@media (max-width: $screen-md) {
    .root {
        font-size: 14px;
    }
}
