$textColor: #fff;
$textDark: #232323;

$bgColor: hsl(160, 100%, 44%);

$default: hsla(138, 100%, 35%, 1);
$defaultHover: hsla(138, 100%, 43%, 1);
$defaultPressed: hsla(138, 100%, 27%, 1);

$dark: hsla(188, 100%, 16%, 1);
$darkHover: hsla(188, 100%, 24%, 1);
$darkPressed: hsla(189, 100%, 8%, 1);

$light: #fff;
