@import "../../../styles/config.scss";

.image {
    @include detailImage;
}

.face {
    position: absolute;
    top: 19.5%;
    left: 19%;
    width: 32%;
    transform: translateZ(0);
}

.faceEyes {
    position: absolute;
    top: 32.3%;
    width: 3.8%;
    will-change: transform;

    &.0 {
        left: 24.6%;
    }

    &.1 {
        left: 37.6%;
    }

    &.animate {
        transition: transform 3s ease-in-out;
        transform: translateX(100%);
    }
}

.faceDot1,
.faceDot2,
.faceDot0 {
    position: absolute;
    width: 2.05%;
    will-change: transform;
}

.faceDot0 {
    top: 41.7%;
    left: 39.5%;

    &.animate {
        transition: transform 0.5s ease-out 2.5s;
        transform: translate(-90%, 90%) scale(0.7);
    }
}

.faceDot1 {
    top: 41.7%;
    left: 28.3%;
}

.faceDot2 {
    top: 41.7%;
    left: 28.3%;
    transform-origin: bottom right;

    &.animate {
        transition: transform 0.5s ease-in 2.9s;
        transform: translate(-100%, -100%);
    }
}

.small0,
.small1,
.small2 {
    position: absolute;
    @include elementsAnimation(2.7s);

    &.animate {
        animation-name: move;
    }
}

.small0 {
    top: 12.5%;
    left: 51%;
    width: 14%;
}

.small1 {
    top: 47.5%;
    left: 55%;
    width: 19%;
}

.small2 {
    top: 72.5%;
    left: 44%;
    width: 12%;
}

@keyframes move {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10%);
    }
    100% {
        transform: translateY(0);
    }
}
