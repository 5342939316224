@import "../../styles/config.scss";
@import "../../styles/shared/logo.scss";

.root {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;

    @media (max-width: $screen-lg) {
        margin-bottom: 20px;
    }
}

.logo {
    position: absolute;
    top: 5px;
    left: 20px;
    margin-left: 0;

    @media (min-width: $screen-tablet-mini) {
        left: 50px;
    }
}

.faqContentWrap {
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    padding: 200px 30px 20px;
    position: absolute;
    top: 0;
    bottom: 0;

    a {
        color: $textColor;
        &:hover {
            color: $textColor;
        }
    }

    @media (max-width: $screen-xl), (max-height: $screen-md) {
        padding: 120px 80px 20px 50px;
    }

    @media (max-width: $screen-md) {
        padding: 40px 20px 20px;
    }

    @media (max-width: $screen-mobile) {
        padding-top: 80px;
    }
}

.faqQuestionsContainer {
    padding-bottom: 50px;

    @media (max-width: $screen-md){
        padding-right: 30px;
    }
}

.faqPageTitle {
    font-size: 60px;
    font-weight: 500;
    margin-bottom: 80px;

    @media (max-width: $screen-xl), (max-height: $screen-md) {
        font-size: 42px;
        margin-bottom: 60px;
    }

    @media (max-width: $screen-mobile-landscape) and (orientation: landscape) {
        margin-bottom: 24px;
        font-size: 32px;
        line-height: 36px;
    }

    @media (max-width: $screen-md) {
        margin-bottom: 40px;
    }

    @media (max-width: $screen-mobile) {
        font-size: 30px;
        margin-bottom: 30px;
    }
}

.faqQuestionBlock {
    font-size: 28px;
    line-height: 1.3;

    &:not(:last-child) {
        margin-bottom: 80px;
    }

    @media (max-width: $screen-xl), (max-height: $screen-md) {
        font-size: 20px;

        &:not(:last-child) {
            margin-bottom: 45px;
        }
    }
    @media (max-width: $screen-md) {
        font-size: 18px;

        &:not(:last-child) {
            margin-bottom: 25px;
        }
    }

    @media (max-width: $screen-mobile) {
        font-size: 17px;
        line-height: 24px;

        &:not(:last-child) {
            margin-bottom: 30px;
        }
    }
}

.faqQuestionTitle {
    margin-bottom: 16px;

    @media (max-width: $screen-xl), (max-height: $screen-md) {
        margin-bottom: 10px;
    }
}

.opened {
    visibility: visible;
    opacity: 1;
    max-width: none;
}

