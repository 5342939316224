@import "../../styles/config.scss";

.dialog {
    position: fixed;
    top: -100%;
    left: 0;
    z-index: $ZSubstrate;
    overflow: hidden;
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    margin: 0 !important;
    padding: 16px 24px;
    background-color: $light;
    transition: transform 0.8s ease-in;
    transform: translateY(0%);

    &.open {
        transition: transform 0.8s ease-out;
        transform: translateY(100%);
    }
}

.closeButton {
    position: absolute;
    top: 16px;
    right: 24px;
    height: auto;
    padding: 0;
    border: 0;

    &,
    &:focus,
    &:hover,
    &:active {
        background-color: transparent !important;
    }
}

.logo {
    height: 32px;
    margin-bottom: 71px;
}

@media (max-width: $screen-mobile) {
    .logo {
        margin-bottom: 51px;
    }
}

@media (max-width: $screen-mobile-landscape) and (orientation: landscape) {
    .logo {
        margin-bottom: 32px;
    }
    .dialog {
        padding: 16px 64px;
    }
    .closeButton {
        right: 68px;
    }
}
