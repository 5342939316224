@import "../../styles/config.scss";

.dotsRightCompleted,
.dotsLeftExit {
    position: absolute;
    width: 22.4%;
    height: auto;
    user-select: none;
}

.dotsRightCompleted {
    right: 0;
    bottom: 0;
    width: auto;
}

.dotsLeftExit {
    bottom: 0;
    left: 0;
    width: auto;
}
