@import "../../../styles/config.scss";

.image {
    @include detailImage;
}

.tool {
    position: absolute;
    top: 18%;
    left: 30%;
    width: 53%;
    transform-origin: top right;
    @include elementsAnimation(10s);

    &.animate {
        animation-name: rotate;
    }
}

@media (max-width: $screen-mobile) {
    .tool {
        &.animate {
            animation-name: rotateMobile;
        }
    }
}

@keyframes rotate {
    0% {
        transform: rotate(90deg);
    }
    50% {
        transform: rotate(-90deg);
    }
    100% {
        transform: rotate(90deg);
    }
}

@keyframes rotateMobile {
    0% {
        transform: rotate(50deg);
    }
    50% {
        transform: rotate(-50deg);
    }
    100% {
        transform: rotate(50deg);
    }
}
