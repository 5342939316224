@import "../../styles/config.scss";

.landing {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    max-width: 820px;
    height: 100%;
    margin-left: 124px;
    -webkit-touch-callout: none;
    user-select: none;

    @include pageAnimation;
}

.title {
    margin-bottom: 48px;
    font-family: $GSBold;
    font-size: 90px;
    line-height: 1;
}

.text {
    margin-bottom: 32px;
    font-family: $GSMed;
    font-size: 28px;
}

@media (max-width: $screen-xl), (max-height: $screen-md) {
    .landing {
        max-width: 585px;
        margin-left: 88px;
    }

    .title {
        font-size: 64px;
    }

    .text {
        font-size: 20px;
    }
}

.button {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent;
    @include button($dark);

    font-size: 20px;
    line-height: 1.4;

    & > svg {
        width: 26px;
        height: 26px;
        fill: $dark;
        margin-left: 4px;
    }

    &:hover,
    &:focus {
        color: $darkHover;

        & > svg {
            fill: $darkHover;
        }
    }

    &:active {
        color: $darkPressed;

        & > svg {
            fill: $darkPressed;
        }
    }
}

@media (max-width: $screen-md) {
    .landing {
        justify-content: flex-start;
        max-width: 473px;
        margin-top: 56px;
        margin-left: 24px;
    }

    .title {
        font-size: 40px;
        line-height: 48px;
    }

    .text {
        line-height: 26px;
        font-weight: 500;
    }
}

@media (max-width: $screen-mobile-landscape) and (orientation: landscape) {
    .landing {
        top: 0;
        justify-content: flex-start;
        max-width: 327px;
        margin-top: 45px;
        margin-left: 64px;
        transition: all 0.5s ease-out;

        &.current {
            transition: all 0.5s ease-out;
        }
    }

    .title {
        margin-bottom: 24px;
        font-size: 32px;
        line-height: 36px;
    }

    .text {
        font-size: 17px;
        line-height: 24px;
        font-weight: 500;
    }
}

@media (max-width: $screen-mobile) {
    .landing {
        top: 0;
        justify-content: flex-start;
        max-width: unset;
        margin-top: 64px;
        margin-left: 24px;
        transition: opacity 0.3s cubic-bezier(0.3, 0.5, 0.7, 1);

        &.current {
            transition: opacity 0.3s ease-out;
        }
    }

    .title {
        margin-bottom: 20px;
        font-size: 32px;
        line-height: 36px;
        font-weight: 600;
    }

    .text {
        font-size: 17px;
        line-height: 24px;
        font-weight: 500;
    }
}
