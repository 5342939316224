@font-face {
    font-family: "GTS Bold";
    src: url("../../assets/fonts/Guardian\ Text\ Sans\ Cy-Bold.otf")
        format("opentype");
}

@font-face {
    font-family: "GS Bold";
    src: url("../../assets/fonts/Guardian\ Sans\ Cy-Bold.otf")
        format("opentype");
}

@font-face {
    font-family: "GS Med";
    src: url("../../assets/fonts/Guardian\ Sans\ Cy-Medium.otf")
        format("opentype");
}
