@import "../../styles/config.scss";

.detail {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    -webkit-touch-callout: none;
    user-select: none;

    @include pageAnimation;

    &.left {
        padding-left: 6%;
    }

    &.right {
        right: 0;
        padding-right: 6%;
    }

    &.apply {
        margin-top: 4%;
    }
}

.article {
    position: relative;
    max-width: 570px;
}

@media (max-width: $screen-xl) {
    .article {
        max-width: 375px;
    }
}

@media (max-height: $screen-md) {
    .article {
        max-width: 500px;
    }

    .detail.grow > .article {
        max-width: 530px;
    }
}

.imageContainer {
    position: absolute;
    opacity: 0;

    &.left {
        left: 100%;
    }

    &.right {
        right: 100%;
    }

    &.visible {
        opacity: 1;
    }

    &.apply {
        top: -203px;
        left: 19%;
    }
}

@media (max-height: $screen-md) {
    .imageContainer.apply {
        top: -132px;
        right: auto;
    }
}

.text {
    @media (min-width: $screen-lg) {
        overflow-y: scroll;
        width: 100%;
        max-width: 100%;
        max-height: 52vh;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }
}
.title {
    margin-bottom: 8px;
    color: $dark;
    font-size: 20px;
    text-transform: uppercase;
}

.subtitle {
    margin-bottom: 24px;
    font-size: 60px;
    line-height: 1.13;
}

.paragraph {
    font-size: 20px;
    line-height: 1.4;

    &:not(:last-child) {
        margin-bottom: 16px;
    }
}

.buttonGroup {
    display: flex;
    margin-top: 32px;

    & > * {
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
        -webkit-tap-highlight-color: transparent;
        @include largeButton;
        &:not(:first-child) {
            margin-left: 8px;
        }
    }
}

.navButtonGroup {
    display: flex;
    margin-top: 43px;

    & button {
        border: none;
    }
}

@media (max-width: $screen-xl), (max-height: $screen-md) {
    .detail {
        &.left {
            padding-left: 4%;
        }

        &.right {
            padding-right: 4%;
        }
    }

    .title {
        margin-bottom: 4px;
        font-size: 16px;
    }

    .subtitle {
        margin-bottom: 20px;
        font-size: 40px;
        line-height: 1;
    }

    .paragraph {
        font-size: 16px;
        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    .buttonGroup {
        margin-top: 24px;

        & > * {
            @include normalButton;
        }
    }

    .navButtonGroup {
        margin-top: 24px;
    }
}

@media (max-width: $screen-md) {
    .article {
        max-width: 473px;
    }

    .imageContainer {
        top: 20px;

        &.apply {
            top: 40px;
            right: auto;
        }

        &.left {
            left: 103%;
        }

        &.right {
            right: 103%;
        }
    }

    .detail {
        justify-content: flex-start;
        margin-top: 56px;
    }

    .title {
        margin-bottom: 8px;
        font-size: 15px;
    }

    .subtitle {
        margin-bottom: 24px;
    }

    .paragraph {
        font-size: 17px;
        line-height: 22px;
        font-weight: 500;
        &:not(:last-child) {
            margin-bottom: 24px;
        }
    }

    .buttonGroup {
        margin-top: 24px;

        & > * {
            @include tabletButton;
        }
    }
}

@media (max-width: $screen-mobile-landscape) and (orientation: landscape) {
    .detail {
        top: 0;
        justify-content: flex-start;
        margin-top: 45px;
        transition: all 0.5s ease-out;

        &.left {
            padding-left: 7.9%;
        }

        &.right {
            padding-right: 7.9%;
        }

        &.grow {
            max-width: 392.15px;
        }

        &.current {
            top: 0;
            transition: all 0.5s ease-out;
        }
    }

    .article {
        max-width: 327px;
    }

    .imageContainer {
        top: -47px;

        &.apply {
            top: -30px;
            right: auto;
        }

        &.left {
            left: 107%;
        }

        &.right {
            right: 107%;
        }

        &.grow {
            top: -30px;
        }
    }

    .title {
        margin-bottom: 12px;
        font-size: 13px;
    }

    .subtitle {
        margin-bottom: 24px;
        font-size: 32px;
        line-height: 36px;
    }

    .paragraph {
        font-size: 17px;
        line-height: 24px;
        font-weight: 500;
        &:not(:last-child) {
            margin-bottom: 24px;
        }
    }

    .buttonGroup {
        margin-top: 24px;

        & > * {
            @include tabletButton;
        }
    }
}

@media (max-width: $screen-mobile) {
    .detail {
        position: fixed;
        top: 64px !important;
        left: 0;
        display: grid;
        grid-template-rows: 150px 1fr 85px;
        height: calc(90% - 64px);
        margin-top: 0;
        transition: unset;

        &.current {
            top: 0;
            padding-right: 6.15%;
            padding-left: 6.15%;
            transition: opacity 0.3s ease-out;
        }

        &.apply {
            margin-top: 0;
        }
    }

    .article {
        overflow-y: scroll;
        max-width: 100%;
        -ms-overflow-style: none;
        scrollbar-width: none;
        margin-top: 20px;
    }

    .article::-webkit-scrollbar {
        display: none;
    }

    .imageContainer {
        position: relative;
        top: 10px;
        width: 165px;
        height: 165px;

        &.left {
            left: 55%;
        }

        &.right {
            right: -50%;
        }

        &.apply {
            top: 20px;
        }
    }

    .title {
        margin-bottom: 20px;
        font-size: 13px;
        font-weight: 600;
    }

    .subtitle {
        margin-bottom: 24px;
        font-size: 32px;
        line-height: 36px;
        font-weight: 600;
    }

    .paragraph {
        font-size: 17px;
        line-height: 24px;
        font-weight: 500;
        &:not(:last-child) {
            margin-bottom: 24px;
        }
    }

    .buttonGroup {
        align-items: center;
        margin-top: 0;

        & > * {
            width: 100%;
        }
    }

    .hiddenOnMobile {
        display: none;
    }

    .navButtonGroup {
        margin-top: 0;
        & > * {
            flex: 1;

            & > button {
                margin-left: auto;
            }
        }
    }
}
