@import "../../../styles/config.scss";

.image {
    @include detailImage;
}

.heart {
    position: absolute;
    @include elementsAnimation(6s);

    &.0 {
        top: 36%;
        left: 47%;
        width: 22%;
        &.animate {
            animation-name: heartbeat0;
        }
    }
    &.1 {
        top: 18%;
        left: 47%;
        width: 13%;
        transform: rotate(-29deg);
        &.animate {
            animation-name: heartbeat29;
        }
    }
    &.2 {
        top: 42.5%;
        left: 34.5%;
        width: 6%;
        transform: rotate(-29deg);
        &.animate {
            animation-name: heartbeat29;
        }
    }
    &.3 {
        top: 42.5%;
        left: 16%;
        width: 7%;
        transform: rotate(-60deg);
        &.animate {
            animation-name: heartbeat60;
        }
    }
    &.4 {
        top: 49%;
        left: 26.5%;
        width: 11%;
        transform: rotate(-47deg);
        &.animate {
            animation-name: heartbeat47;
        }
    }
}

$scale: 0.8;

@keyframes heartbeat0 {
    0% {
        transform: scale($scale);
    }
    25% {
        transform: scale(1);
    }
    50% {
        transform: scale($scale);
    }
    75% {
        transform: scale(1);
    }
    100% {
        transform: scale($scale);
    }
}

@keyframes heartbeat29 {
    0% {
        transform: scale($scale) rotate(-29deg);
    }
    25% {
        transform: scale(1) rotate(-29deg);
    }
    50% {
        transform: scale($scale) rotate(-29deg);
    }
    75% {
        transform: scale(1) rotate(-29deg);
    }
    100% {
        transform: scale($scale) rotate(-29deg);
    }
}

@keyframes heartbeat47 {
    0% {
        transform: scale($scale) rotate(-47deg);
    }
    25% {
        transform: scale(1) rotate(-47deg);
    }
    50% {
        transform: scale($scale) rotate(-47deg);
    }
    75% {
        transform: scale(1) rotate(-47deg);
    }
    100% {
        transform: scale($scale) rotate(-47deg);
    }
}

@keyframes heartbeat60 {
    0% {
        transform: scale($scale) rotate(-60deg);
    }
    25% {
        transform: scale(1) rotate(-60deg);
    }
    50% {
        transform: scale($scale) rotate(-60deg);
    }
    75% {
        transform: scale(1) rotate(-60deg);
    }
    100% {
        transform: scale($scale) rotate(-60deg);
    }
}
