@import "../../styles/config.scss";

.mapComponent {
    position: absolute;
    bottom: 0;
    left: 48%;
    width: 125.28vh;
    height: 78vh;
    transform: translate(-50%) scale(1);
    will-change: transform;
    -webkit-touch-callout: none;
    user-select: none;
}

@media (max-aspect-ratio: 4/3) {
    .mapComponent {
        top: 50%;
        bottom: auto;
        width: 90vw;
        height: 58vw;
        transform: translate(-51%, -50%) scale(1);
    }
}

.mapContainer {
    position: absolute;
    left: 50%;
    height: 100%;
    transition: transform $animationTime ease-in-out;
    transform: translate(-50%);
    will-change: transform;

    &.landing {
        transform: translate(4%, 18%) scale(3.15);
    }

    &.do {
        transform: translate(-170%, 120%) scale(3.15);
    }

    &.grow {
        transform: translate(-202%, 48%) scale(3.15);
    }

    &.tech {
        transform: translate(-10%, 72%) scale(3.15);
    }

    &.team {
        transform: translate(-139%, 6%) scale(2);
    }

    &.need {
        transform: translate(10%, -3%) scale(3.15);
    }

    &.apply {
        transform: translate(63%, -102%) scale(3);
    }
}

@media (max-height: $screen-md) {
    .mapContainer {
        &.landing {
            transform: translate(9%, 20%) scale(3);
        }

        &.do {
            transform: translate(-165%, 111%) scale(3);
        }

        &.grow {
            transform: translate(-193%, 60%) scale(3);
        }

        &.tech {
            transform: translate(-21%, 66%) scale(3);
        }

        &.team {
            transform: translate(-143%, -21%) scale(2.5);
        }

        &.need {
            transform: translate(22%, -5%) scale(3);
        }

        &.apply {
            transform: translate(45%, -63%) scale(2.5);
        }
    }
}

@media (max-width: $screen-md), (max-height: $screen-md) {
    .mapContainer {
        &.landing {
            transform: translate(-30%, 40%) scale(1.5);
        }

        &.do {
            transform: translate(-130%, 70%) scale(1.5);
        }

        &.grow {
            transform: translate(-130%, 90%) scale(1.5);
        }

        &.tech {
            transform: translate(-23%, 75%) scale(1.5);
        }

        &.team {
            transform: translate(-127%, 80%) scale(2);
        }

        &.need {
            transform: translate(-22%, 50%) scale(1.5);
        }

        &.apply {
            transform: translate(7%, -15%) scale(2);
        }
    }
}

@media (max-width: $screen-mobile-landscape) and (orientation: landscape) {
    .mapContainer {
        transition: transform 0.5s cubic-bezier(0.3, 0.5, 0.7, 1);

        &.landing,
        &.do,
        &.grow,
        &.tech,
        &.team,
        &.need,
        &.apply {
            transition: transform 0.5s cubic-bezier(0.3, 0.5, 0.7, 1);
        }

        &.landing {
            transform: translate(-21%, -35%) scale(1);
        }

        &.do {
            transform: translate(-126%, -1%) scale(1);
        }

        &.grow {
            transform: translate(-127%, -14%) scale(1);
        }

        &.tech {
            transform: translate(-28%, -13%) scale(1);
        }

        &.team {
            transform: translate(-124%, -36%) scale(1);
        }

        &.need {
            transform: translate(-20%, -28%) scale(1);
        }

        &.apply {
            transform: translate(-13%, -40%) scale(1);
        }
    }
}

.mapImage {
    width: auto;
    height: 116%;
    will-change: transform;
    -webkit-touch-callout: none;
    user-select: none;
}

.triangles {
    position: absolute;
    top: 33%;
    left: 32%;
    width: 4.8%;
    height: auto;
    transition: transform $animationTime ease-in-out;
    will-change: transform;
    -webkit-touch-callout: none;
    user-select: none;

    &.landing {
        transform: translate(230%, 361%) scale(1.9) rotate(128deg);
    }

    &.do {
        transform: translate(420%, 284%) scale(1.9) rotate(128deg);
    }

    &.grow {
        transform: translate(400%, 382%) scale(1.9) rotate(128deg);
    }

    &.tech {
        transform: translate(329%, 408%) scale(1.9) rotate(128deg);
    }

    &.team {
        transform: translate(542%, 330%) scale(1.9) rotate(128deg);
    }

    &.need {
        transform: translate(342%, 330%) scale(1.9) rotate(128deg);
    }

    &.apply {
        transform: translate(342%, 330%) scale(1.9) rotate(128deg);
    }
}

@media (max-height: $screen-md) {
    .triangles {
        &.landing {
            transform: translate(230%, 361%) scale(1.9) rotate(128deg);
        }

        &.do {
            transform: translate(190%, 323%) scale(1.9) rotate(-128deg);
        }

        &.grow {
            transform: translate(371%, 510%) scale(1.9) rotate(128deg);
        }

        &.team {
            transform: translate(542%, 430%) scale(1.9) rotate(128deg);
        }
    }
}

@media (max-width: $screen-md), (max-height: $screen-md) {
    .triangles {
        display: none;
    }
}

.dotsLeft,
.dotsRight,
.dotsBottomLeft,
.dotsBottomRight {
    position: absolute;
    width: 30.7%;
    height: auto;
    transition: all $animationTime ease-in-out;
    will-change: transform;
    -webkit-touch-callout: none;
    user-select: none;
}

.dotsLeft {
    top: 39.1%;
    left: -10.5%;
    transform: rotate(225deg);

    &.landing {
        transform: translate(-200%, 0%) rotate(225deg) scale(1.2);
    }

    &.do {
        transform: translate(-66%, -42%) rotate(225deg) scale(1.2);
    }

    &.grow {
        transform: translate(-183%, -42%) rotate(225deg) scale(1.2);
    }

    &.tech {
        transform: translate(-183%, -42%) rotate(225deg) scale(1.2);
    }

    &.team {
        transform: translate(-183%, -42%) rotate(225deg) scale(1.2);
    }

    &.need {
        transform: translate(-183%, -42%) rotate(225deg) scale(1.2);
    }

    &.apply {
        transform: translate(-183%, -42%) rotate(225deg) scale(1.2);
    }
}

.dotsRight {
    top: 10.8%;
    right: -13.6%;

    &.landing {
        transform: translate(31%, -66%) scale(1.2);
    }

    &.do {
        transform: translate(-23%, -134%) scale(1.2);
    }

    &.grow {
        transform: translate(3%, -133%) scale(1.2);
    }

    &.tech {
        transform: translate(50%, -71%) scale(1.2);
    }

    &.team {
        transform: translate(20%, -108%) scale(1.2);
    }

    &.need {
        transform: translate(20%, -156%) scale(1.2);
    }

    &.apply {
        transform: translate(20%, -156%) scale(1.2);
    }
}

.dotsBottomLeft {
    bottom: -18%;
    left: 8%;

    &.landing {
        transform: translate(-29%, 29%) scale(1.2);
    }

    &.do {
        transform: translate(-16%, -9%) scale(1.2);
    }

    &.grow {
        transform: translate(-1%, 17%) scale(1.2);
    }

    &.tech {
        transform: translate(-57%, 66%) scale(1.2);
    }

    &.team {
        transform: translate(-58%, 32%) scale(1.2);
    }

    &.need {
        transform: translate(-22%, 58%) scale(1.2);
    }

    &.apply {
        transform: translate(-83%, 23%) scale(1.2);
    }
}

.dotsBottomRight {
    right: -9%;
    bottom: -28%;
    transform: rotate(152deg);

    &.landing {
        transform: translate(6%, 46%) rotate(152deg) scale(1.2);
    }

    &.do {
        transform: translate(20%, 14%) rotate(152deg) scale(1.2);
    }

    &.grow {
        transform: translate(113%, -30%) rotate(154deg) scale(1.2);
    }

    &.tech {
        transform: translate(96%, 5%) rotate(152deg) scale(1.2);
    }

    &.team {
        transform: translate(121%, -38%) rotate(155deg) scale(1.2);
    }

    &.need {
        transform: translate(96%, -13%) rotate(152deg) scale(1.2);
    }

    &.apply {
        transform: translate(96%, -13%) rotate(152deg) scale(1.2);
    }
}

@media (min-aspect-ratio: 2/1) {
    .dotsLeft {
        &.tech {
            transform: translate(-233%, -42%) rotate(225deg) scale(1.2);
        }

        &.need {
            transform: translate(-233%, -42%) rotate(225deg) scale(1.2);
        }

        &.apply {
            transform: translate(-233%, -42%) rotate(225deg) scale(1.2);
        }
    }

    .dotsBottomLeft {
        &.apply {
            transform: translate(-83%, 37%) scale(1.2);
        }
    }

    .dotsBottomRight {
        &.do {
            transform: translate(20%, 30%) rotate(152deg) scale(1.2);
        }

        &.grow {
            transform: translate(122%, 6%) rotate(154deg) scale(1.2);
        }

        &.team {
            transform: translate(122%, 6%) rotate(154deg) scale(1.2);
        }
    }
}

@media (max-width: $screen-md), (max-height: $screen-md) {
    .dotsLeft {
        &.do,
        &.grow,
        &.tech,
        &.need,
        &.apply {
            display: none;
        }
    }

    .dotsRight {
        &.landing {
            transform: translate(55%, 15%) scale(2) rotate(-180deg);
        }

        &.tech {
            transform: translate(30%, 160%) scale(2) rotate(-180deg);
        }

        &.team,
        &.grow,
        &.do,
        &.need,
        &.apply {
            display: none;
        }
    }

    .dotsBottomLeft {
        &.landing {
            transform: translate(0%, 97%) scale(2) rotate(100deg);
        }

        &.do {
            transform: translate(-90%, -25%) scale(2) rotate(-180deg);
        }

        &.grow {
            transform: translate(-90%, 30%) scale(2) rotate(-180deg);
        }

        &.tech {
            transform: translate(0%, 210%) scale(2) rotate(89deg);
        }

        &.team {
            transform: translate(-40%, -50%) scale(2) rotate(-180deg);
        }

        &.need {
            transform: translate(25%, 70%) scale(2) rotate(-180deg);
        }

        &.apply {
            transform: translate(10%, 120%) scale(2) rotate(89deg);
        }
    }

    .dotsBottomRight {
        &.landing {
            display: none;
        }

        &.do {
            transform: translate(-50%, 130%) rotate(180deg) scale(2);
        }

        &.grow {
            transform: translate(-62%, 120%) rotate(181deg) scale(2);
        }

        &.team {
            transform: translate(-50%, 120%) rotate(180deg) scale(2);
        }
    }
}

.button {
    position: absolute;
    opacity: 1;
    transition: opacity $buttonAnimationTime ease-in $buttonDelay;
    @include animateIcon(0);
    @include animateIcon(1);
    @include animateIcon(2);
    @include animateIcon(3);
    @include animateIcon(4);
    @include animateIcon(5);
    @include animateIcon(6);
    @include animateIcon(7);

    will-change: transform;
    -webkit-touch-callout: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent;

    &.hidden {
        opacity: 0;
        transition: opacity $buttonAnimationTime ease-out;
    }

    &.0 {
        top: -6%;
        right: 31%;
    }

    &.1 {
        top: 20%;
        left: 88%;
    }

    &.2 {
        top: 16.5%;
        right: 50.7%;
    }

    &.3 {
        top: 31%;
        left: 72.4%;
    }

    &.4 {
        top: 46.5%;
        right: 59.6%;
    }

    &.5 {
        top: 70.3%;
        right: 71.8%;
    }

    &.6 {
        top: 84.5%;
        left: 62%;
    }

    &.7 {
        top: 51.5%;
        left: 86%;
        white-space: nowrap;
    }
}

@media (max-aspect-ratio: 4/3) and (max-width: $screen-md) {
    .button {
        &.5 {
            top: 66%;
            right: 72%;
        }
    }
}

@media (max-width: $screen-md) {
    .button {
        &.1 {
            top: 11%;
            left: 77.8%;
        }
    }
}

@media (max-height: 720px) {
    .button {
        height: 42px;
        padding: 14px 21px;
        font-size: 14px;
        line-height: 1;
        border-radius: 21px;
    }
}

.icon {
    position: absolute;
    width: 5.8%;
    max-width: 103px;
    transform-origin: bottom;
    will-change: transform;

    &.0 {
        top: -7%;
        left: 70.94%;
    }

    &.1 {
        top: 18.9%;
        left: 80.1%;
    }

    &.2 {
        top: 15.4%;
        left: 51.3%;
    }

    &.3 {
        top: 29.8%;
        left: 64.8%;
    }

    &.4 {
        top: 45.6%;
        left: 42.5%;
    }

    &.5 {
        top: 70%;
        left: 30%;
    }

    &.6 {
        top: 83.4%;
        left: 54.3%;
    }

    &.7 {
        top: 51%;
        left: 78.5%;
    }
}

@keyframes bounce {
    0%,
    100% {
        transform: translateY(0);
    }
    20%,
    50%,
    80% {
        transform: translateY(0) scale(1, 0.92);
    }
    40% {
        transform: translateY(-36%) scale(1, 1.14);
    }
    60% {
        transform: translateY(-18%) scale(1, 1.07);
    }
}

@media (max-width: $screen-mobile-landscape) and (orientation: landscape) {
    .mapComponent {
        position: relative;
        top: 15%;
        bottom: unset;
        left: 10%;
        width: 854.47px;
        height: 722.09px;
        transform: unset;
        will-change: unset;
    }

    .dotsLeft,
    .dotsRight,
    .dotsBottomLeft,
    .dotsBottomRight {
        display: none;
    }

    .button {
        transition-delay: unset;

        &.0 {
            top: -4.8%;
            right: 29.2%;
        }

        &.1 {
            top: 22.7%;
            left: 86.5%;
        }

        &.2 {
            top: 17.5%;
            right: 47.5%;
        }

        &.3 {
            top: 32.8%;
            left: 71.5%;
        }

        &.4 {
            top: 48%;
            right: 58.6%;
        }

        &.5 {
            top: 72.5%;
            right: 70%;
        }

        &.6 {
            top: 71.3%;
            left: 57.1%;
        }

        &.7 {
            top: 55%;
            left: 85%;
        }
    }

    .icon {
        width: 4.4%;

        &.0 {
            top: -5%;
            left: 72%;
        }

        &.1 {
            top: 23%;
            left: 81%;
        }

        &.2 {
            top: 17.5%;
            left: 54%;
        }

        &.3 {
            top: 33%;
            left: 66%;
        }

        &.4 {
            top: 48.2%;
            left: 43.5%;
        }

        &.5 {
            top: 72.5%;
            left: 31%;
        }

        &.6 {
            top: 71.5%;
            left: 51.5%;
        }

        &.7 {
            top: 55%;
            left: 79.5%;
        }
    }
}

@media (max-width: $screen-mobile) {
    .mapComponent {
        position: relative;
        top: 4%;
        bottom: unset;
        left: 30%;
        width: fit-content;
        height: 550px;
        transform: unset;
        will-change: unset;
    }

    .mapContainer {
        left: unset;
        transition: transform 0.6s cubic-bezier(0.3, 0.5, 0.7, 1),
            opacity 0.5s cubic-bezier(0.3, 0.5, 0.7, 1);
        transform: none;
        will-change: unset;
        & > button {
            transform: unset;

            &.hidden {
                transition: unset;
            }
        }

        &.landing {
            transition: transform 1s cubic-bezier(0.3, 0.5, 0.7, 1);
            transform: translate(-7%, 6%);
        }

        &.mapContainerHidden {
            opacity: 0;
            transition: unset;
            transform: unset;
        }
    }

    .icon {
        width: 4.4%;

        &.0 {
            top: -3%;
            left: 70%;
        }

        &.1 {
            top: 24%;
            left: 81%;
        }

        &.2 {
            top: 20.5%;
            left: 52%;
        }

        &.3 {
            top: 35%;
            left: 66%;
        }

        &.4 {
            top: 49.2%;
            left: 43.5%;
        }

        &.5 {
            top: 73.5%;
            left: 31%;
        }

        &.6 {
            top: 71.5%;
            left: 51.5%;
        }

        &.7 {
            top: 55%;
            left: 79.5%;
        }
    }

    .mapImage {
        will-change: unset;
    }

    .dotsLeft,
    .dotsRight,
    .dotsBottomLeft,
    .dotsBottomRight {
        display: none;
        transform: unset;
    }

    .button {
        transition: opacity 0.2s cubic-bezier(0.3, 0.5, 0.7, 1);

        &.0 {
            top: -3.3%;
            right: 31.2%;
        }

        &.1 {
            top: 24.2%;
            left: 86.5%;
        }

        &.2 {
            top: 20.1%;
            right: 49.5%;
        }

        &.3 {
            top: 34.8%;
            left: 71.5%;
        }

        &.4 {
            top: 49%;
            right: 58.6%;
        }

        &.5 {
            top: 68.5%;
            right: 69.8%;
        }

        &.6 {
            top: 71.3%;
            left: 57.1%;
        }

        &.7 {
            top: 55%;
            left: 86%;
        }
    }
}
