@import "../../styles/config.scss";

.main {
    position: relative;
    overflow: hidden;
    height: calc(100% - 96px); // headers height - 96px
    background-color: $bgColor;
}

.signUp,
.faqButton {
    position: absolute;
    top: 32px;
    right: 32px;
    z-index: $ZPageButton;
    opacity: 1;
    transition: opacity $buttonAnimationTime ease-in $buttonDelay;

    &.hidden {
        opacity: 0;
        transition: opacity $buttonAnimationTime ease-out;
    }
}

.faqButton {
    right: 32px;
}

@media (max-height: $screen-md) {
    .signUp,
    .faqButton {
        top: 16px;
    }
}

.up {
    @include button($dark);

    position: fixed;
    right: 32px;
    bottom: 32px;
    opacity: 1;
    transition: opacity $buttonAnimationTime ease-in;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent;

    &.hidden {
        opacity: 0;
        transition: opacity $buttonAnimationTime ease-out;
    }
}

.upImage {
    width: auto;
    height: 62px;
}

@media (max-width: $screen-tablet) {
    .main {
        height: 100%;
    }

    .up {
        top: 100px;
        left: 25px;
        width: 40px;
        height: 40px;
        background: rgba(255, 255, 255, 0.8);
        border-radius: 23px;
    }

    .upImage {
        width: 24px;
        height: 21px;
        margin-bottom: 2px;
    }
}

@media (max-width: $screen-mobile),
    (max-width: $screen-mobile-landscape) and (orientation: landscape) {
    .main {
        overflow-y: scroll;
        height: 100%;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .main.map {
        overflow: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .main.map::-webkit-scrollbar,
    .main::-webkit-scrollbar {
        display: none;
    }

    .up {
        top: 90px;
        left: 50px;
        transition: all 0.5s ease-out;

        &.hidden {
            transition: all 0.5s ease-out;
        }
    }
}

@media (max-width: $screen-mobile) {
    .main {
        overflow-y: hidden;
        height: calc(100vh - 64px);
    }

    .main.map {
        overflow: hidden;
        overflow-x: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .finger {
        position: absolute;
        bottom: 20%;
        left: calc(50% + 320px);
        z-index: -1;
        opacity: 0;
        width: 55px;
        height: 55px;
        background: url("../../assets/images/finger.png") center/cover;
        animation-name: fingerMove;
        content: "";
        @include elementsAnimation(2s, linear, 1);
    }

    .fingerUnderlay {
        position: fixed;
        top: 0;
        left: 0;
        z-index: -1;
        opacity: 0;
        overflow-y: hidden;
        width: 270%;
        height: 110%;
        background-color: rgba(0, 0, 0, 0.6);
        animation-name: underlayAppear;
        content: "";
        @include elementsAnimation(2s, linear, 1);
    }

    .up {
        top: 80px;
        left: 24px;
        transition: unset;

        &.hidden {
            transition: unset;
        }
    }
}

@keyframes fingerMove {
    0% {
        z-index: 2;
        opacity: 0;
        transform: translateX(0);
    }
    25% {
        z-index: 2;
        opacity: 1;
        transform: translateX(20px);
    }
    50% {
        z-index: 2;
        opacity: 1;
        transform: translateX(0);
    }
    75% {
        z-index: 2;
        opacity: 1;
        transform: translateX(-20px);
    }
    100% {
        z-index: 2;
        opacity: 0;
        transform: translateX(0);
    }
}

@keyframes underlayAppear {
    0% {
        z-index: 1;
        opacity: 0;
    }
    25% {
        z-index: 1;
        opacity: 1;
    }
    50% {
        z-index: 1;
        opacity: 1;
    }
    75% {
        z-index: 1;
        opacity: 1;
    }
    100% {
        z-index: 1;
        opacity: 0;
    }
}
