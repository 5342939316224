@import "../config.scss";

.logo {
    width: 30vmin;
    max-width: 180px;
    margin-top: 32px;
    margin-bottom: 100px;
    margin-left: 32px;

    @media (max-width: $screen-xl), (max-height: $screen-lg) {
        margin-bottom: 64px;
    }

    @media (max-height: $screen-md) {
        margin-bottom: 36px;
    }

    @media (max-width: $screen-tablet) {
        max-width: 87px;
        margin-top: 16px;
        margin-left: 24px;
    }

    @media (max-width: $screen-mobile-landscape) and (orientation: landscape) {
        margin-top: 7px;
        margin-left: 0;
    }

    @media (max-width: $screen-mobile) {
        margin-bottom: 33px;
        margin-left: 0;
    }
}
