@import "../../styles/config.scss";
@import "../../styles/shared/logo.scss";

.root {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;

    // remove scrollbar for these resolutions because overflow-y set in Substrate root
    // also this sets fixed background for full height
    &.overflow {
        @media (max-width: $screen-mobile-landscape) and (orientation: landscape) {
            overflow-y: hidden;
        }

        @media (max-width: $screen-mobile) {
            overflow-y: hidden;
        }
    }
}

.logo {
    position: absolute;
    top: 5px;
    left: 20px;
    margin-left: 0;

    @media (min-width: $screen-tablet-mini) {
        left: 50px;
    }
}

.videos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    padding: 70px 20px 30px;
    position: absolute;
    top: 0;
    bottom: 0;

    @media (min-width: $screen-tablet) {
        padding-top: 120px;
    }

    @media (min-width: 1800px) {
        padding-top: 70px;
    }
}

.video {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 48px;

    @media (min-width: $screen-tablet-mini) {
        width: 50%;
        flex: 1 1 50%;
        padding: 0 30px 48px;
    }
}

.videoTitle {
    font-size: 26px;
    line-height: 30px;
    color: $textColor;
    font-weight: 500;
    margin-bottom: 16px;

    @media (min-width: $screen-tablet-mini) {
        min-height: 60px;
        display: flex;
        align-items: center;
    }
}

.videoCover {
    width: 100%;
    max-width: 570px;

    img {
        max-width: 100%;
        height: auto;
        cursor: pointer;
        transition-duration: 0.1s;

        &:hover {
            box-shadow: 0 5px 15px rgba(0, 219, 66, 0.08);
        }
    }
}

.iframeContainer {
    position: fixed;
    z-index: 3;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    background: hsla(188, 100%, 24%, 0.58);
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    max-width: 100%;
    transition-duration: 0.3s;
}

.opened {
    visibility: visible;
    opacity: 1;
    max-width: none;
}

.videoIframe {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media (max-width: $screen-xl) {
        top: 50%;
    }
}

// close button
.closeButton {
    position: absolute;
    top: 32px;
    right: 32px;
    height: auto;
    padding: 0;
    border: 0;

    &,
    &:focus,
    &:hover,
    &:active {
        background-color: transparent !important;
    }

    @media (max-width: $screen-tablet) {
        top: 24px;
        right: 28px;
    }

    @media (max-width: $screen-mobile-landscape) and (orientation: landscape) {
        top: 16px;
        right: 68px;
    }

    @media (max-width: $screen-mobile) {
        right: 28px;
    }
}
