@import "../../../../styles/config.scss";

.burger {
    height: auto;
    margin: 0;
    padding: 0;
    border: 0;
    cursor: pointer;
}

.items {
    display: flex;
    flex-direction: column;
    font-family: $GTSBold;
    font-size: 16px;
    font-weight: 700;

    & > * {
        margin-bottom: 32px;
        color: $textDark;
        font-size: 16px;
        line-height: 24px;
        text-decoration: none;
        text-transform: uppercase;

        &:nth-child(3) {
            margin-bottom: 56px;
        }
    }

    .buttonGroup {
        display: flex;

        & > * {
            @include tabletButton;

            &:not(:last-child) {
                margin-right: 24px;
            }
        }
    }
}

@media (max-width: $screen-mobile-landscape) and (orientation: landscape) {
    .items > *:nth-child(3) {
        margin-bottom: 32px;
    }
}
