@mixin button($color) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    color: $color;
    font-family: $GTSBold;
    text-decoration: none;
    text-transform: uppercase;
    background-color: transparent;
    border: 2px solid transparent;
    outline: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    cursor: pointer;
}

@mixin colorButton($normal, $hover, $pressed, $textColor) {
    color: $textColor;
    background-color: $normal;
    border-color: $normal;

    &.outlined {
        color: $normal;
        background-color: transparent;
    }

    &:hover:not(:disabled),
    &:focus:not(:disabled) {
        color: $textColor;
        background-color: $hover;
        border-color: $hover;
    }

    &:active:not(:disabled) {
        color: $textColor;
        background-color: $pressed;
        border-color: $pressed;
    }
}

@mixin normalButton {
    height: 48px;
    padding: 12px 24px;
    font-size: 16px;
    line-height: 24px;
    border-width: 2px;
    border-radius: 24px;
}

@mixin largeButton {
    height: 64px;
    padding: 18px 48px;
    font-size: 20px;
    line-height: 28px;
    border-width: 3px;
    border-radius: 32px;
}

@mixin tabletButton {
    width: 152px;
    height: 45px;
    padding: 0;
    font-size: 13px;
    border-width: 2px;
}

@mixin largeTabletButton {
    width: 188px;
    height: 45px;
    padding: 0;
    font-size: 13px;
    border-width: 2px;
}

@mixin mediaMaxWidthSm {
    @media (max-width: #{$screen-sm}) {
        @content;
    }
}

@mixin animateIcon($num: 0) {
    &.#{$num}:hover:not(:disabled) ~ .icon.#{$num} {
        animation: bounce 1.9s ease infinite;
    }
}

@mixin pageAnimation {
    z-index: -1;
    opacity: 0;
    transition: opacity $pageAnimationTime linear,
        z-index $pageAnimationTime linear;

    &.current {
        z-index: $ZPage;
        opacity: 1;
        transition: opacity $pageAnimationTime linear $pageDelay,
            z-index $pageAnimationTime linear $pageDelay;
    }
}

@mixin elementsAnimation(
    $duration,
    $easing: ease-in-out,
    $iteration: infinite
) {
    animation-duration: $duration;
    animation-timing-function: $easing;
    animation-iteration-count: $iteration;
    will-change: transform;
}

@mixin detailImage {
    width: 267px;
    height: auto;
    transition: opacity 0.01s ease-in-out;
    transform: translateZ(0);
    backface-visibility: hidden;
    -webkit-user-select: none;
    user-select: none;

    @media (max-height: $screen-md) {
        width: 180px;
    }

    @media (max-width: $screen-md) {
        width: 165px;
    }
}
