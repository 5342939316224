@import "../../../styles/config.scss";

.result {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 65%;
    font-family: $GSMed;
}

.text {
    margin-bottom: 48px;
    font-size: 60px;
}

.rightAnswers {
    font-size: 90px;
}

.buttonGroup {
    display: flex;

    & > * {
        @include largeButton;

        &:not(:last-child) {
            margin-right: 24px;
        }
    }
}

@media (max-height: $screen-md) {
    .text {
        margin-bottom: 40px;
        font-size: 50px;
    }

    .rightAnswers {
        font-size: 75px;
    }

    .buttonGroup > * {
        @include normalButton;
    }
}

@media (max-width: $screen-tablet) {
    .rightAnswers {
        margin-bottom: 12px;
        font-size: 36px;
        line-height: 44px;
    }

    .text {
        margin-bottom: 32px;
        font-size: 22px;
        line-height: 28px;
    }

    .buttonGroup > * {
        @include largeTabletButton;
    }
}

@media (max-width: $screen-mobile-landscape) and (orientation: landscape) {
    .rightAnswers {
        margin-bottom: 8px;
        font-size: 32px;
        line-height: 36px;
    }
}

@media (max-width: $screen-mobile) {
    .buttonGroup {
        flex-direction: column;
        width: 100%;

        & > * {
            width: 100%;
            margin: 0;

            &:first-child {
                margin-bottom: 16px;
            }
        }
    }

    .rightAnswers {
        margin-bottom: 8px;
        font-size: 32px;
        line-height: 36px;
    }

    .result {
        height: auto;
        margin-top: 14px;
    }
}
