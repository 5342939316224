@import "../../../styles/config.scss";

.image {
    @include detailImage;
}

.coinLeft,
.coinRight {
    position: absolute;
    @include elementsAnimation(3s);
}

.coinLeft {
    top: 7%;
    left: 4%;
    width: 59%;
    &.animate {
        animation-name: moveLeft;
    }
}

.coinRight {
    top: 29%;
    right: 8%;
    width: 23%;
    &.animate {
        animation-name: moveRight;
    }

    animation-duration: 2s;
}

@keyframes moveLeft {
    0% {
        transform: rotate(2.5deg);
    }
    50% {
        transform: rotate(-2.5deg);
    }
    100% {
        transform: rotate(2.5deg);
    }
}

@keyframes moveRight {
    0% {
        transform: rotate(-3deg);
    }
    50% {
        transform: rotate(3deg);
    }
    100% {
        transform: rotate(-3deg);
    }
}
